var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',_vm._l((_vm.options),function(opt,index){return _c('v-row',{key:(index + " :: " + (opt.title))},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(opt.title))])]),_c('v-row',_vm._l((opt.answers),function(answer,idx){return _c('v-col',{key:(idx + " :: " + (answer.title)),attrs:{"cols":"auto"}},[_c('v-btn',{class:("px-5" + (_vm.isSelectedOption(opt.id, answer.id)
              ? ' font-weight-bold primary'
              : '')),style:(_vm.isSelectedOption(opt.ida, answer.id)
              ? 'border-width: 0.2rem; pointer-events: none'
              : ''),attrs:{"block":"","outlined":"","rounded":"","color":_vm.isSelectedOption(opt.id, answer.id) ? 'white' : 'primary'},on:{"click":function () { return _vm.handleClickSelectOption({
                answer: answer,
                id: opt.id,
                isMultiple: opt.is_multiple,
              }); }}},[_vm._v(" "+_vm._s(answer.title)+"  "),(_vm.isSelectedOption(opt.id, answer.id))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-bold ")]):_vm._e()],1)],1)}),1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }