<template>
  <section>
    <v-row v-for="(opt, index) in options" :key="`${index} :: ${opt.title}`">
      <v-col cols="12">
        <h4>{{ opt.title }}</h4>
      </v-col>
      <v-row>
        <v-col
          v-for="(answer, idx) in opt.answers"
          :key="`${idx} :: ${answer.title}`"
          cols="auto"
        >
          <v-btn
            block
            outlined
            rounded
            @click="
              () =>
                handleClickSelectOption({
                  answer,
                  id: opt.id,
                  isMultiple: opt.is_multiple,
                })
            "
            :class="`px-5${
              isSelectedOption(opt.id, answer.id)
                ? ' font-weight-bold primary'
                : ''
            }`"
            :color="isSelectedOption(opt.id, answer.id) ? 'white' : 'primary'"
            :style="
              isSelectedOption(opt.ida, answer.id)
                ? 'border-width: 0.2rem; pointer-events: none'
                : ''
            "
          >
            {{ answer.title }}&nbsp;
            <v-icon v-if="isSelectedOption(opt.id, answer.id)" small>
              mdi-check-bold
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </section>
</template>
<script>
import { reservationsCtrl } from "@/controllers";

export default {
  name: "OptionsReservations",
  props: {
    optionsSelected: {
      type: Array,
      default: null,
    },
  },
  data() {
    return { options: [] };
  },

  async mounted() {
    const options = await reservationsCtrl.getOptionsReservations();
    this.options = options;
  },

  methods: {
    handleClickSelectOption(optionSelected) {
      const { id, answer } = optionSelected;
      const foundOption = this.findOption(id);

      if (!foundOption) return this.$emit("add:optionSelected", optionSelected);

      const { answers } = foundOption;
      const foundAnswer = this.findAnswer(answers, answer.id);

      if (foundAnswer)
        return this.$emit("delete:optionSelected", {
          ...optionSelected,
          deleteOption: answers.length <= 1,
        });

      return this.$emit("update:optionSelected", optionSelected);
    },
    isSelectedOption(optId, answerId) {
      return (
        this.optionsSelected &&
        this.optionsSelected.some(({ id, answers }) => {
          if (id !== optId) return false;
          return answers.some((id) => id === answerId);
        })
      );
    },
    findOption(optId) {
      return this.optionsSelected.find(({ id }) => id === optId);
    },
    findAnswer(answers, answerId) {
      return answers.find((id) => id === answerId);
    },
  },
};
</script>
